@import 'colors';
.notifications {
  width: 100%;
  overflow-y: scroll;

  .notifications-header {
    background-color: #042b43;
    display: flex;
  }

  .notifications-header-text {
    margin: 31px auto 16px;
    max-width: 580px;
    width: 90%;
    color: #242424;
    font-family: var(--primary-font);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 33px;
  }

  .notifications-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 580px;
    margin: 0 auto;
    padding: 15px 0;
  }

  .notifications-button {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.6px;
    cursor: pointer;
  }

  .notifications-title {
    display: flex;
    align-items: center;
    height: 25px;
    width: 50px;
    color: #242424;
    font-family: var(--primary-font);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
  }

  .notifications-title-badge {
    height: 20px;
    min-width: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    background-color: #af365f;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    padding: 1px 4px 0 3px;
    margin-left: 8px;
  }

  .notifications-holder {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .notifications-row {
    display: flex;
    padding: 15px 16px;
    border-bottom: 1px solid #e4e6e7;
    align-items: center;
    text-decoration: none;
  }

  .notifications-row-avatar {
    border-radius: 8px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .notifications-row-text {
    color: $black;
    letter-spacing: -0.15px;
    margin: 0;
  }

  .notifications-row-time {
    opacity: 0.5;
    color: $fg-color-main;
    font-size: 13px;
  }

  .notification-link {
    color: #4e5a63;
    text-decoration: none;
    font-weight: 600;
  }

  .notification-badge {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 3px solid #fbb811;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .notification-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .ant-skeleton {
    padding: 15px 16px;
    margin-bottom: 0;
    border-bottom: 1px solid #e4e6e7;
    border-radius: 0;
    box-shadow: none;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .ant-skeleton-avatar {
    border-radius: 8px;
  }

  .notification-item-buttons-container {
    display: flex;
    flex-direction: row;
    padding: 6px 0;
    justify-content: flex-start;
  }

  .notification-item-button {
    height: 30px;
    background-color: var(--primary-color);
    border-radius: 8px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 15px;
    justify-self: flex-end;
    width: 50%;
  }

  .button-opposite {
    background-color: white;
    border-width: 1px;
    color: var(--primary-color);
    border-style: solid;
  }
}
