@import 'colors';

.field-container,
.checkbox {
  margin-bottom: 3px;
}

.field-container {
  position: relative;
  padding-top: 15px;

  &.active {
    .field-label {
      padding: 0 4px;
      font-size: 1.2em;
      transform: translate(0, 4px) scale(0.677);
      color: $fg-color-main;
      background-color: #f4f4f4;
    }
  }

  &.active.has-error {
    .field-label {
      color: #be265f !important;
    }
  }

  &.has-error {
    input,
    textarea {
      border-color: #be265f !important;
    }
  }

  &.focused {
    input,
    textarea {
      border-color: var(--primary-color) !important;
    }
  }

  .field-label {
    top: 3px;
    left: 13px;
    position: absolute;
    transform: translate(0, 24px) scale(1);
    transform-origin: top left;
    margin-bottom: 0;
    pointer-events: none;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color: #fff;
    color: rgba(80, 90, 98, 0.5);
    line-height: 24px;
    border-radius: 12px;
  }

  .right-component {
    z-index: 1;
    position: absolute;
    top: 15px;
    right: 1px;
    width: 42px;
    height: 48px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.datepicker {
      z-index: 100;
      top: 22px;
      right: -5px;
    }
    .calendar-icon {
      margin: 12px 10px;
    }

    & > button {
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
    }

    & ~ input {
      padding-right: 42px;
    }
    svg.calendar-icon {
      z-index: 100;
    }
  }

  input,
  textarea {
    &:disabled {
      opacity: 0.6;
    }
  }

  input,
  textarea,
  .select__control {
    width: 100%;
    padding: 0 15px;
    border: 1px solid $fg-color-main;
    border-radius: 8px;
    transition: border-color 0.5s ease;
    box-shadow: none;

    &::placeholder {
      color: #808399;
    }

    &:focus {
      border-color: $fg-color-main;
      outline: none;

      & + .field-label {
        color: $fg-color-main;
      }
    }
  }

  input {
    height: 48px;
    -webkit-appearance: none;
  }

  textarea {
    -webkit-appearance: none;
    min-height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    @media (max-width: 420px) {
      min-height: 115px;
    }
  }

  .select {
    input {
      max-height: 28px;
    }

    &__control {
      min-height: 42px;
      padding: 0;

      &--is-focused {
        border-color: #e0e2ea;
      }
    }
    &__value-container {
      padding: 2px 15px;
    }

    &__placeholder {
      color: #808399;
    }

    &__indicator-separator {
      display: none;
    }

    .wishlist-select__option {
      padding: 5px 15px;
      cursor: pointer;
      font-weight: 600;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #eceef4;
      }

      span {
        margin-left: 10px;
      }

      img {
        width: 25px;
        height: 25px;
        border-radius: 25px;
      }
    }
  }

  &__error,
  &__helper-text,
  .password-strength-label {
    font-size: 1rem !important;
  }

  &__error-wrapper {
    background-color: #be265f;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 12px 12px 8px;
    margin-top: -6px;
  }

  &__error {
    color: #ffffff;
    font-weight: 600;
    font-family: var(--primary-font), serif;
  }

  &__helper-text {
    color: #3575ad;
    font-weight: 600;
  }

  &__account-helper-text {
    color: #5a656d;
    font-size: 15px;
    padding: 0px;
    margin: 0px;
  }

  .password-strength-label {
    color: #808399;
    float: right;
  }

  .password-strength-line {
    display: flex;
    width: 100%;
    margin: 2px -1.5px;

    div {
      flex: 1;
      height: 3px;
      margin: 0 3px;
      background-color: #e0e2ea;
    }

    &.strength-level {
      &-0 {
        div:nth-child(-n + 0) {
          background-color: #e0e2ea;
        }
      }
      &-1 {
        div:nth-child(-n + 1) {
          background-color: #ff1414;
        }
      }
      &-2 {
        div:nth-child(-n + 2) {
          background-color: #ffc23f;
        }
      }
      &-3 {
        div:nth-child(-n + 3) {
          background-color: #9bd95c;
        }
      }
      &-4 {
        div:nth-child(-n + 4) {
          background-color: #6cbd13;
        }
      }
    }
  }
}

.radio,
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  line-height: 1.2;
  cursor: pointer;

  &.orange {
    &:before {
      border: 1px solid red;
    }

    .checkmark {
      border: 1px solid red;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1px solid #1c8ffa;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    background-color: transparent;
    border: 1px solid #808399;
  }
}
.checkbox {
  &.orange {
    padding-left: 45px;
    .checkmark {
      left: 10px;
    }
    input {
      &:checked ~ .checkmark {
        background-color: red;
      }
    }
    @media screen and (max-width: 768px) {
      padding-left: 35px;
      .checkmark {
        left: 0px;
      }
    }
  }

  input {
    &:checked ~ .checkmark {
      background-color: #808399;
    }
  }

  .checkmark {
    border-radius: 2px;

    &:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
.radio {
  &.orange {
    input {
      &:checked ~ .checkmark:after {
        background-color: red;
      }
    }
  }

  input {
    &:checked ~ .checkmark:after {
      background-color: #808399;
    }
  }

  .checkmark {
    border-radius: 18px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 8px;
      height: 8px;
      border-radius: 8px;
    }
  }
}

// .react-datepicker-popper {
//   margin-top: 0 !important;
//   box-shadow: 0 4px 32px 0 rgba(204, 204, 204, 0.24) !important;
//   z-index: 99 !important;
// }
.react-datepicker {
  border: none !important;
  // padding: 0.7rem !important;
  &__triangle {
    display: none;
  }
  // &__header {
  //   background-color: #ffffff !important;
  //   border: none !important;
  // }
  &__current-month {
    color: var(--primary-color) !important;
    text-transform: uppercase;
    padding-bottom: 10px;
  }

  &__day--outside-month {
    visibility: hidden;
  }

  &__day--keyboard-selected {
    background-color: var(--primary-color) !important;
    border-radius: 50% !important;
    width: 40px !important;
    height: 40px;
    margin: 0 !important;
    display: inline-flex !important;
    justify-content: center;
    flex-direction: column;
  }
  &__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: var(--primary-color) !important;
    border-radius: 50% !important;
    width: 40px !important;
    height: 40px;
    margin: 0 !important;
    display: inline-flex !important;
    justify-content: center;
    flex-direction: column;
  }
  &__year-option {
    &:hover {
      background-color: #f0f0f0 !important;
    }
  }
  &__year-dropdown {
    background-color: white !important;
  }
}
// .react-datepicker__navigation--next {
//   background: url('../images/right.svg') no-repeat !important;
//   width: 24px !important;
//   height: 30px !important;
//   border: none !important;
//   transform: rotate(-90deg);
//   top: 18px;
// }
// .react-datepicker__navigation--previous {
//   background: url('../images/right.svg') no-repeat !important;
//   width: 24px !important;
//   height: 30px !important;
//   border: none !important;
//   transform: rotate(90deg);
//   top: 18px !important;
// }
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  width: 100%;
}
.switch-holder {
  margin: -0.2rem auto 2rem auto;
  background-color: #eceef4;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  &__text {
    color: #808399;
    font-size: 1rem;
    margin-bottom: 0;
  }
  label {
    margin: 3px 0 3px 10px;
    height: 18px;
  }
}
.wishlist-url-field-container {
  .url {
    color: black;
    word-break: break-word;
  }
}

.input-general-error {
  color: #fff;
  background-color: #be265f;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 12px 12px 8px;
  margin-top: -6px;
  font-weight: 600;
}
