.sidebar-group-block {
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  margin-bottom: 12px;

  .sidebar-group-block-image-holder {
    border-radius: 8px 8px 8px 8px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
    background-color: #eeeeee;
  }

  .sidebar-group-block-image {
    height: 80px;
    width: 100%;
    object-fit: cover;

    &__default {
      width: 70%;
      height: auto;
    }
  }

  .sidebar-group-block-title {
    color: #0F2B41;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    max-height: 36px;
    padding: 0 10px;
    margin: 0;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sidebar-group-block-event-title {
    width: 180px;
  }

  .sidebar-group-block-subtitle {
    color: rgb(133,133,133);
    font-size: 12px;
    font-weight: 100;
    padding: 0;
    margin: 5px 0 0 10px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }

  .sidebar-group-block-notification {
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff3b30;
    color: #fff;
    border-radius: 10px;
    font-size: 12px;
    margin: 8px 0;
    padding: 5px;
  }
}
