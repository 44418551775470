.create-feed {
  border-radius: 8px;
  overflow: hidden;

  .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    span > span[data-offset-key] > span[data-text] {
      //color: red;
    }
  }

  .create-feed-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(4, 43, 67, 0.8);
  }

  .create-feed-holder {
    position: relative;
    z-index: 11;
    display: flex;
    flex-direction: column;

    &.active {
      max-height: calc(100vh - 52px - 84px - 25px);
    }
  }

  .create-feed-content {
    padding: 0 16px 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 0;
    overflow-y: auto;

    &.collapsed {
      padding-bottom: 13px;
    }
  }

  .create-feed-group-row {
    margin-bottom: 3px;
    font-size: 16px;
    width: 100%;

    .field-container {
      padding-top: 0;
      margin-top: 15px;
      margin-bottom: 0;
      border: 1px solid $fg-color-main;
      border-radius: 8px;

      &.focused {
        border-color: var(--primary-color);
      }

      input {
        border: none !important;
        z-index: 9998;
        position: relative;
      }

      .field-label {
        top: -12px;
        z-index: 9999;
      }
    }
  }

  .create-feed-group-selected {
    display: flex;
    align-items: center;
    margin-top: 24px;
    color: #4e5a63;
  }

  .create-feed-selected-group {
    margin: 0 8px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--primary-color);
    display: flex;
    align-items: center;
    padding-right: 8px;
    font-weight: 600;
    color: #042b43;
  }

  .create-feed-selected-group-image {
    border-radius: 0;
    margin-right: 8px;
  }

  .create-feed-change-button {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
  }

  textarea {
    resize: none;
    display: block;
    position: relative;
  }

  .create-feed-extra-block {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  .create-feed-textarea-holder {
    border-radius: 8px;
    border: 1px solid $fg-color-main;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 0;

    &.focused {
      border-color: var(--primary-color);
    }

    .field-container {
      padding: 0;
      margin: 0;
      align-self: stretch;
    }

    .field-label {
      top: -12px;
    }
  }

  .create-feed-notice-image-holder {
    padding: 13px 16px 10px;
    position: relative;
    min-height: 0;
    max-height: 300px;
    // display: flex;
    overflow: hidden;
    width: 100%;
  }
  .create-feed-notice-image {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
  }
  .create-feed-notice-remove {
    position: absolute;
    top: 0;
    right: 6px;
    right: 6px;
    background-color: #fff;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    cursor: pointer;
  }

  .create-feed-notice-video-remove {
    position: relative;
    margin-left: 16px;
  }

  .create-feed-notice-image-remove-icon {
    width: 22px;
  }

  .create-feed-add-icon {
    margin-right: 6px;
    width: 18px;
    height: 18px;
  }

  .create-feed-add-text {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-top: 12px;
    align-self: flex-start;
    cursor: pointer;
    letter-spacing: 0.25px;
    flex-shrink: 0;
  }

  .custom-textarea {
    border: none;
  }

  .create-feed-post-button {
    width: 146px;
    align-self: flex-end;
    margin-top: 14px;
  }

  .create-feed-options-holder {
    padding: 16px;
    background-color: #f4f4f4;
    width: 100%;
    border-radius: 0 0 8px 8px;
    min-height: 0;
    overflow-y: auto;
  }

  .create-feed-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .field-container {
      width: 100%;
    }
  }

  .create-feed-option-remove {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    margin-left: 7px;
    cursor: pointer;
  }

  .create-feed-select-holder {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    position: relative;
  }

  .create-feed-select {
    height: 48px;
    border: 1px solid $fg-color-main;
    border-radius: 8px;
    overflow: hidden;
    color: #2d2d2d;
    font-size: 16px;
    padding: 0 20px 0 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #fff;

    .create-feed-select-dropdown-option {
      padding: 0;
    }
  }

  .create-feed-select-label {
    font-size: 16px;
    letter-spacing: 0.2px;
    background-color: #fff;
    margin-left: 10px;
    margin-bottom: -8px;
    align-self: flex-start;
    line-height: 16px;
    display: block;
    position: relative;
    z-index: 1;
    padding: 0 4px;
    transform: translate(0, 24px) scale(1);
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, font-size 0.2s;
    color: rgba(80, 90, 98, 0.5);

    &.active {
      transform: none;
      color: $fg-color-main;
      font-size: 13px;
    }
  }

  .create-feed-select-error {
    color: #fff;
    background-color: #be265f;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 12px 12px 8px;
    margin-top: -6px;
    font-weight: 600;
  }

  .hidden-label {
    visibility: hidden;
  }

  .create-feed-select-icon {
    position: absolute;
    top: 35px;
    right: 15px;
    color: $fg-color-main;
  }

  .create-feed-highfive-block {
    border-radius: 8px;
    margin-top: 15px;
    height: initial;
    min-height: 216px;
    padding-top: 137px;
    flex-shrink: 0;

    .feed-item-highfive-header-image {
      top: 30px;
    }
  }

  .create-feed-highfive-colleague-row {
    padding: 0 16px;
    width: 100%;
    position: relative;
    z-index: 1;

    &.selected {
      margin-top: 15px;
      border-top: 1px solid rgba(0, 117, 172, 0.35);
      display: flex;
      align-items: center;
      background-color: #e2eff5;
    }
  }

  .create-feed-selected-colleague {
    margin-left: 0;
  }

  .create-feed-selected-colleague-cancel {
    margin-left: 8px;
    cursor: pointer;
  }

  .not-selected {
    background-color: transparent;
    border: 2px dashed var(--primary-color);
    cursor: pointer;
  }

  .create-feed-event-content {
    .field-container {
      margin-bottom: 3px;
    }
  }

  .create-feed-event-image-holder {
    height: 187px;
    background-color: #e1e3e4;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .create-feed-event-image-placeholder {
  }
  .create-feed-event-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .create-feed-time-select {
    display: flex;
    justify-content: space-between;
    flex: 1;

    .field-container {
      width: calc(50% - 8px);
    }
  }

  .create-feed-time-select-extra {
    margin-top: 15px;
    padding: 1px 16px;
    display: flex;
    background-color: #f4f4f4;
    border: 1px solid #cdd0d2;
    border-radius: 8px;
    align-items: center;

    .field-container {
      margin-bottom: 15px;
    }
  }

  .create-feed-time-cancel {
    cursor: pointer;
    margin-left: 8px;
  }

  .create-feed-content-input-wrapper {
    .field-container {
      margin-bottom: 0;
    }
  }

  .create-feed-empty-space {
    width: 100%;
    height: 16px;
    flex-shrink: 0;
    background: transparent;
  }

  .create-feed-error-message {
    color: $red;
  }
}

.create-feed-group-option {
  min-height: 42px;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-top: 1px solid rgba(78, 90, 99, 0.25);
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.16px;
}

.create-feed-group-option-image {
  margin-right: 8px;
  border-radius: 8px;
  overflow: hidden;
}

.create-feed-select-dropdown-option {
  min-height: 20px;
  display: flex;
  align-items: center;
  padding: 5px 16px;
  font-family: var(--primary-font), sans-serif;
  font-size: 16px;
  color: #2d2d2d;
}

.autocomplete-target {
  position: relative;
}
.create-feed-group-row-dropdown {
  padding-top: 9px;
  top: -9px !important;
  border: 1px solid var(--primary-color);
  border-top: none;
  border-radius: 0 0 8px 8px;
}

.create-feed-select-group-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 12px;
}

.create-feed-select-group-dropdown-title {
  font-family: var(--primary-font), sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
}

.create-feed-select-group-dropdown-description {
  font-family: var(--primary-font), sans-serif;
  font-size: 16px;
  color: #505a62;
  white-space: normal;
}

.create-feed-select-dropdown {
  padding-top: 0px;
}

.create-feed-select-group-innerCcontainer {
  width: 100%;
  padding-left: 6px;
}
