.sidebar-more-block {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #D1D5DA;
  margin-bottom: 16px;
  display: block;
  cursor: pointer;

  .sidebar-more-block-image-holder {
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
  }

  .sidebar-more-block-image {
    max-height: 100%;
    max-width: 100%
  }

  .sidebar-more-block-text {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    margin: 0;
    background-color: #fff;
  }
}
