@import 'colors';

.create-list-modal {
  max-width: 920px;
  top: 10%;
}
.modal.fade.show {
  background-color: rgba(4, 43, 67, 0.8);
}
.modal-header {
  border-radius: 0.5rem 0.5rem 0 0;
  text-align: left;
  background-color: #f6f7fb;
  border-bottom: none;
  padding: 1rem 1rem 1rem 1rem;
  .modal-title {
    font-size: 20px;
    color: var(--primary-color);
    font-weight: bold;
    margin: auto;
  }
  .modal-sub-title {
    font-size: 16px;
    color: $black;
    font-weight: normal;
  }
  .close {
    margin: -1rem -1rem -1rem;
  }
}
.modal-body {
  .field-description {
    color: $black;
    font-size: 12px;
    font-weight: 600;
    margin-top: -1rem;
  }
  @media screen and (max-width: 767px) {
    padding: 1rem;
  }
}
.list-picture-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  &__title {
    color: #808399;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }
  &__holder {
    display: flex;
  }
  &__picture {
    height: 70px;
    min-width: 70px;
    border-radius: 50%;
  }
  &__btn-upload {
    width: 38% !important;
    margin: 1rem 10px 1rem 20px;
    white-space: nowrap;
    font-size: 12px !important;
  }
  &__btn-delete {
    width: 18% !important;
    margin-top: 1rem;
    font-size: 12px !important;
  }
}
.modal-dialog {
  max-width: 350px;
}
.modal-content {
  border-radius: 0.5rem;
  .message {
    &-title {
      background-color: #fff;

      h5 {
        font-size: 20px;
        color: #0b1039;
        line-height: 1;
      }
      button {
        right: 15px;
        position: absolute;
        top: 15px;
      }
      &.error {
        //background-image: url("../svgs/giggle-errorpattern.svg");
        .modal-title {
          color: #ff0000;
        }
      }
    }
    &-body {
      padding: 0 17px 17px;
      p {
        max-width: 330px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $black;
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
        @media screen and (max-width: 767px) {
          max-width: 350px;
        }
        span {
          color: #0f2b41c2;
          a {
            color: var(--primary-color);
            font-weight: 600;
          }
        }
      }
    }
    &-footer {
      button {
        max-width: 100%;
      }
    }
    .divider {
        height: 1px;
        margin: 16px 0 8px;
        background-color: rgba(15,43,65,0.25);
    }
  }
  @media (max-width: 450px) {
    .message {
      &-title {
        h5 {
          font-size: 2.5em;
        }
      }
      &-body {
        p {
          max-width: 250px;
        }
      }
      &-footer {
        button {
          max-width: 160px;
        }
      }
    }
  }
  @media (max-width: 320px) {
    .message {
      &-title {
        h5 {
          font-size: 2.3em;
        }
      }
      &-body {
        p {
          max-width: 250px;
        }
      }
      &-footer {
        button {
          max-width: 135px;
        }
      }
    }
  }
}
.modal-footer {
  border-top: none;
  justify-content: center;
  padding: 0 1rem 1rem;
  .modal-confirm,
  .modal-back {
    width: 25%;
  }
  .modal-back {
    color: #808399;
  }
  @media screen and (max-width: 767px) {
    .modal-back,
    .modal-confirm {
      width: 40%;
    }
  }
}
.modal-close {
  cursor: pointer;
  width: 15px;
}

.calendar-icon {
  z-index: 100;
}

.modal-back-link {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  display: flex;
  //flex: 1;
  align-items: center;
  line-height: 24px;
  cursor: pointer;
  position: absolute;

  .back-link-icon {
    margin-right: 5px;
    font-weight: normal;
    height: 15px;
  }
}

.modal-header-title {
  font-size: 20px;
  color: $black;
  font-weight: bold;
  // flex: 1;
  // position: absolute;
  margin: auto;
}
