@import './colors.scss';

.progress-bar-holder {
  height: 52px;
  padding: 15px 0 13px;
  background-color: #f0f0f0;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);

  span {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.15px;
    color: #0f2b41;
    font-family: var(--primary-font), sans-serif;
  }

  .progress-bar {
    background: var(--primary-color);
    border-radius: 6px;
  }

  .progress {
    height: 12px;
    border-radius: 6px;
    background-color: rgba(15, 43, 65, 0.1);
  }

  .skip-link {
    font-size: 15px;
    font-weight: 600;
    color: var(--primary-color);
    margin-left: 25px;
    cursor: pointer;
  }
}
