@import 'colors';

.single-challenge {
  width: 100%;
  max-width: 580px;
  margin: 25px auto 0;

  &__back-text {
    color: var(--primary-color);
    font-family: var(--primary-font), sans-serif;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &__item {
    border-radius: 8px;
    box-shadow: 0 1px 8px 0 rgba(78, 90, 99, 0.15);
    background-color: #fff;
    width: 100%;
    margin-top: 15px;
    overflow: hidden;
  }

  &__item-content {
    padding: 17px;

    h5 {
      font-size: 22px;
      font-weight: 600;
      font-family: var(--primary-font), sans-serif;
      color: #0f2b41;
    }

    span {
      color: #505a62;
      font-size: 16px;
      font-family: var(--primary-font), sans-serif;
      font-weight: 600;
    }

    p {
      color: #505a62;
      font-size: 16px;
      font-family: var(--primary-font), sans-serif;
      margin-top: 15px;
    }
  }

  &__item-content-badge {
    display: flex;
    align-items: center;

    &.badge {
      span {
        color: #0f2b41;
      }
    }
  }

  &__progress-bar-container {
    display: flex;
    flex-wrap: nowrap;
  }

  &__progress-bar {
    padding: 0 15px;
    flex: 1;
  }

  &__progress-bar-update {
    width: 140px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
  }

  &__progress-bar-update-button {
    border-radius: 16px;
    background-color: var(--primary-color);
    width: 126px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    font-family: var(--primary-font), sans-serif;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.65;
    }
  }

  &__toggle-status {
    background-color: #f0f0f0;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #ced0d2;
    text-align: center;

    h6 {
      color: #0f2b41;
      font-family: var(--primary-font), sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
    }
  }

  &__button {
    height: 30px;
    background-color: var(--primary-color);
    border-radius: 17px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 15px;
    justify-self: flex-end;
  }
}
