@import 'colors';

.sign-in {
  height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .container-with-shadow {
    border: 1px solid #e0e2ea;
    background-color: #ffffff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    width: 80%;
    max-width: 460px;
  }

  .logo-holder {
    background: linear-gradient(40.73deg, #0d1a28 0%, #102a3e 100%);
    // padding: 60px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .form-holder {
    padding: 0 20px 20px;
  }

  .form-title {
    color: #0f2b41;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.32px;
    margin: 15px 0 -5px;
    line-height: 38px;
  }

  .forgot-pass {
    text-transform: uppercase;
    color: var(--primary-color);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.25px;
    font-weight: bold;
    cursor: pointer;
  }

  .username-info {
    color: #505a62;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 10px;
  }

  .hr-devider-line {
    border-top: 1px solid #e0e2ea;
    height: 1px;
    box-sizing: content-box;
  }

  .text-color-grey {
    color: #808399;
  }
}

.login-outer {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  overflow-y: scroll;
  background-color: #F4F4F4;
}

.login-innerleft {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #F4F4F4;
}

.no-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.login-content {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.login-error {
  font-size: 11px;
  font-weight: 600;
  color: #800;
}

.login-innerright {
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.login-imageright {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-logo {
  width: 290px;
  height: 90px;
}
.form-button-login {
  width: 200px;
}
