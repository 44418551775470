.notifications-sidebar {
  position: fixed;
  overflow-y: scroll;
  padding-top: 25px;
  padding-right: 30px;
  flex-shrink: 0;
  height: calc( 100vh - 84px );

  .notifications-sidebar-header {
    display: flex;
    flex:1;
    align-items: center;
    justify-content:flex-start;
  }

  .notifications-sidebar-header-text {
    margin: 0;
    color: #0F2B41;
    font-size: 18px;
    font-weight: 600;
    width:100%;
    margin-top: 5px;
  }

  .notifications-sidebar-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
  }

  .notifications-sidebar-button {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.6px;
    cursor: pointer;
  }

  .notifications-sidebar-title {
    color: $black;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.32px;
    display: flex;
    align-items: center;
  }

  .notifications-sidebar-title-badge {
    height: 20px;
    min-width: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    background-color: #AF365F;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    padding: 1px 4px 0 3px;
    margin-left: 8px;
  }

  .notifications-sidebar-holder {
    border-radius: 10px;
    overflow: hidden;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .notifications-sidebar-row {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    // align-items: center;
    text-decoration: none;
    background-color: white;
    margin-bottom: 10px;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,.1);
  }

  .notifications-sidebar-row-avatar {
    border-radius: 8px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .notifications-sidebar-row-text {
    color: $black;
    letter-spacing: -0.15px;
    margin: 0;
    width: 200px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // height: 100px;
  }

  .notifications-sidebar-row-time {
    opacity: 0.5;
    color: $fg-color-main;
    font-size: 13px;
  }

  .notification-sidebar-link {
    color: #4E5A63;
    text-decoration: none;
    font-weight: 600;
  }

  .notification-sidebar-badge {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 3px solid #FBB811;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .notification-sidebar-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .ant-skeleton {
    padding: 15px 16px;
    margin-bottom: 0;
    border-bottom: 1px solid #E4E6E7;
    border-radius: 0;
    box-shadow: none;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .ant-skeleton-avatar {
    border-radius: 8px;
  }

  .notification-sidebar-item-buttons-container {
    display: flex;
    flex-direction: row;
    padding: 6px 0;
    justify-content: flex-start;
  }

  .notification-sidebar-item-button {
    height: 30px;
    background-color: var(--primary-color);
    border-radius: 8px;
    padding: 0 10px;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 15px;
    justify-self: flex-end;
    width: 50%;
  }

  .button-opposite {
    background-color: white;
    border-width: 1px;
    color: var(--primary-color);
    border-style: solid;
  }

  .notifications-sidebar-three-dots-menu {
    z-index: 1;
    position: absolute;
    flex-direction: column;
    margin-top: 110px;
    margin-left: 10px;
    width: 320px;
    display: flex;
    background-color: white;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(.2,.2,.2,.2);
    cursor: pointer;
  }

  .notifications-sidebar-three-dots-menu-button {
    color: rgb(133,133,133);
    font-size: 15px;
    font-weight: 600;
    width:100%;
    display: flex;
    flex: 1;
    align-items:center;
    margin: 0;
    padding: 6px 0 6px 0;
  }
}
