@import 'colors';

.knowledgebase {
  height: 100%;
  display: flex;
  align-items: flex-start;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;

  .knowledgebase-header {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .knowledgebase-header-text {
    color: $black;
    font-size: 38px;
    font-weight: bold;
    line-height: 58px;
    letter-spacing: -0.75px;
    margin: 31px auto 16px;
    width: 90%;
  }

  .knowledgebase-header-title {
    height: 31px;
    width: 86px;
    color: #242424;
    font-family: var(--primary-font);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 20px auto 10px;
    width: 90%;
    line-height: 33px;
    text-align: justify;
  }

  .knowledgebase-header-subtitle-text {
    color: #505a62;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.75px;
    margin: 0px auto 0px;
    width: 90%;
  }

  .knowledgebase-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 15px 0;
  }

  .knowledgebase-button {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.6px;
    cursor: pointer;
  }

  .knowledgebase-title {
    color: $black;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.32px;
    display: flex;
    align-items: center;
  }

  .knowledgebase-title-badge {
    height: 20px;
    min-width: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    background-color: #af365f;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    padding: 1px 4px 0 3px;
    margin-left: 8px;
  }

  .knowledgebase-holder {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .knowledgebase-row {
    display: flex;
    padding: 15px 16px;
    border-bottom: 1px solid #e4e6e7;
    align-items: center;
    text-decoration: none;
  }

  .knowledgebase-row-avatar {
    border-radius: 8px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .knowledgebase-row-text {
    color: $black;
    letter-spacing: -0.15px;
    margin: 0;
  }

  .knowledgebase-row-time {
    opacity: 0.5;
    color: $fg-color-main;
    font-size: 13px;
  }

  .knowledgebase-link {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 600;
  }

  .knowledgebase-badge {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 3px solid #fbb811;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .knowledgebase-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .ant-skeleton {
    padding: 15px 16px;
    margin-bottom: 0;
    border-bottom: 1px solid #e4e6e7;
    border-radius: 0;
    box-shadow: none;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .ant-skeleton-avatar {
    border-radius: 8px;
  }
}
