.group-item-cont {
  display: flex;
  min-width: 100%;
  padding: 16px;
  & > div {
    font-size: 15px;
  }
}

.group-item-details {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  border: 1px;
  border-radius: 5px;
  width: 200px;
}

.group-item-members {
  color: var(--primary-color);
  font-weight: 600;
}

.group-name-container {
  word-break: break-all;
}

.group-item-name {
  font-weight: 600;
  color: $grey;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: $grey;
  }
}

.group-item-button {
  color: var(--primary-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 15px;
  justify-self: flex-end;
  &.disabled-b {
    opacity: 0.5;
    cursor: default;
  }
}

.group-item-button-leave {
  color: var(--primary-color);
}

.group-item-button-request-sent {
  color: $fg-color-main;
}
