@import 'colors';

.create-group-modal-holder {
  margin-top: 20px;
  margin-bottom: auto;
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
}

.manage-people {
  &__save {
    border-radius: 10px;
    width: 200px;
    background-color: var(--primary-color);
    color: #fff;
    height: 48px;
    font-size: 17px;
    font-family: var(--primary-font), sans-serif;
    font-weight: 700;
    letter-spacing: 0.47px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__discard {
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    background-color: #fff;
    color: var(--primary-color);
    width: 200px;
    height: 48px;
    font-size: 17px;
    font-family: var(--primary-font), sans-serif;
    font-weight: 700;
    letter-spacing: 0.47px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__button {
    border-radius: 25px;
    width: 146px;
    height: 48px;
    font-size: 17px;
    font-family: var(--primary-font), sans-serif;
    font-weight: 700;
    letter-spacing: 0.47px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__invite {
    background-color: var(--primary-color);
    color: #fff;

    &.disabled {
      background-color: #44afe0;
      opacity: 50%;
      color: #fff;
    }
  }
}

.create-group {
  margin-bottom: 50px;

  .modal-header {
    padding-bottom: 0;
  }
  .modal-body {
    padding-top: 10px;
    padding-bottom: 0;
  }

  .field-container {
    margin-bottom: 3px;
  }

  &__attachment-placeholder {
    background-color: #e1e3e4;
  }

  &__category-selector {
    -webkit-appearance: none;
    color: rgba(80, 90, 98, 0.5);
    border: 1px solid #505a62;
  }

  &__invite-colleagues {
    background-color: #e2eff5;
    border-radius: 8px !important;

    .field-container {
      border: 1px solid $fg-color-main;
      border-radius: 8px;
      padding-bottom: 0;
      padding-top: 0;
      margin-top: 15px;
      margin-bottom: 0;
      position: relative;
      z-index: 1;

      &.focused,
      &.active {
        border-color: var(--primary-color);
        z-index: 0;
      }

      input {
        border: none;
        background-color: #fff;
      }
      .field-label {
        top: -12px;
        z-index: 9999;
      }
    }
  }

  &__nevermind {
    background-color: white;
    border-width: 2px;
    color: var(--primary-color);
    border-style: solid;
    margin-right: 20px;
  }

  &__button {
    border-radius: 8px;
    width: 146px;
    height: 48px;
    font-size: 17px;
    font-family: var(--primary-font), sans-serif;
    font-weight: 700;
    letter-spacing: 0.47px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__create {
    background-color: var(--primary-color);
    color: #fff;
  }

  &__spacer {
    height: 16px;
  }

  &__people-select {
    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }

    border-radius: 0 0 8px 8px;
    margin-top: -12px;
    padding-top: 11px;
    background: white;
    position: relative;
    border: 1px solid var(--primary-color);
    border-top: none;
  }

  &__selected-people {
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    display: flex;
    margin-right: 8px;
    align-items: center;
    color: #042b43;
    font-weight: 600;
    overflow: hidden;
    padding-right: 8px;
    height: 38px;
    line-height: 19px;
    margin-bottom: 8px;

    h6 {
      color: #042b43;
      font-weight: 600;
    }
  }

  .modal-title {
    padding-top: 0;
    color: $black;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.32px;
  }

  &__close {
    opacity: 1;
    cursor: pointer;
  }

  &__image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #e1e3e4;
    width: 100%;
    max-width: 270px;
    height: 187px;
    margin: 0 auto;
    flex-shrink: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image_default {
    width: 88px;
  }

  &__add-button {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.25px;
    align-self: center;
    margin-top: 12px;
    cursor: pointer;
  }

  &__add-button-icon {
    margin-right: 6px;
    width: 18px;
  }

  &__selected-title {
    color: #042b43;
    font-weight: 600;
  }

  &__remove-people {
    margin-left: 8px;
    cursor: pointer;
  }

  &__selected-avatar {
    border-radius: 8px 0 0 8px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  &__invite-colleagues-row {
    display: flex;
    align-items: center;
    padding: 8px 13px;
    color: var(--primary-color);
    font-weight: 600;
    border-top: 1px solid rgba(78, 90, 99, 0.25);
  }

  &__invite-colleagues-avatar {
    margin-right: 8px;
    border-radius: 8px;
    flex-shrink: 0;
  }
}
