.sidebar {
  position: fixed;
  max-width: 330px;
  width: 100%;
  background-color: #f9f9f9;
  padding-top: 25px;
  padding-left: 30px;
  padding-right: 30px;
  flex-shrink: 0;
  bottom: 0;
  overflow-y: scroll;
  height: calc(100% - 84px);

  .sidebar-block {
    margin-top: 21px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .sidebar-block-header {
    display: flex;
    justify-content: space-between;
    line-height: 36px;
  }

  .sidebar-block-title {
    color: #0f2b41;
    font-size: 18px;
    font-weight: 600;
  }

  .sidebar-block-button {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .sidebar-block-button-icon {
    width: 18px;
    margin-right: 6px;
  }

  .sidebar-block-content {
    display: flex;
    flex-wrap: wrap;
  }

  .sidebar-chart-progress-container {
    display: flex;
    justify-content: center;

    &.dark {
      background-color: $black-blue;
    }
  }

  .circle {
    margin: 27px auto;
    width: 176px;
    height: 176px;

    .ant-progress-circle {
      transform: rotate(-175deg);
    }
  }

  .sidebar-chart-progress-text {
    color: #ffffff;
    font-size: 44px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sidebar-chart-progress-text-small {
    font-size: 16px;
  }

  .sidebar-block-button-group {
    display: flex;
  }

  .sidebar-block-button-new-groups {
    padding-left: 10px;
  }

  .sidebar-block-groups-menu {
    z-index: 1;
    position: absolute;
    flex-direction: column;
    margin-top: 40px;
    margin-right: 10px;
    right: 0;
    width: 85%;
    max-width: 320px;
    display: flex;
    background-color: white;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0.2, 0.2, 0.2, 0.2);
    cursor: pointer;
  }

  .sidebar-block-groups-menu-button {
    height: 23px;
    width: 110px;
    color: #505a62;
    font-family: var(--primary-font);
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
  }
  .sidebar-block-groups-menu-button:first-child {
    margin-top: 10px;
  }
  .sidebar-block-groups-menu-button:last-child {
    margin-bottom: 10px;
  }
}
