.thumbnail-image {
  &__person {
    width: 42px;
    height: 42px;
    border-radius: 8px;
    object-fit: cover;
  }

  &__group {
    width: 100%;
    max-height: 67px;
    object-fit: cover;
    height: 67px;
  }
}
