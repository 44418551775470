@import 'colors';

.validate-block {
  border: 1px solid $red;
  border-radius: 10px;
  padding: 15px;
  ul {
    margin-bottom: 0;
  }
  li,
  p {
    font-size: 16px;
    color: $red;
    font-weight: bold;
    font-family: var(--primary-font), sans-serif;
    letter-spacing: -0.19px;
  }
}

html,
body,
#root,
.wrapper {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper-content {
  flex: 1;
  overflow-y: auto;
  background-color: white;
  width: 100%;
  align-items: center;
  display: contents;
}

.dark-background {
  background-color: #042b43;
}

.page-title {
  color: #242424;
  font-size: 38px;
  font-weight: bold;
  line-height: 58px;
  letter-spacing: -0.75px;
  margin: 0 auto 16px;
}

.center-content {
  align-items: center;
  justify-content: center;
}
