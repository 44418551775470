.challenges {
  width: 100%;
  max-width: 580px;

  .placeholder-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h5 {
    font-size: 22px;
    font-weight: 600;
    font-family: var(--primary-font), sans-serif;
    color: #0f2b41;
    margin-bottom: 12px;
    margin-top: 36px;
  }

  p {
    color: #505a62;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: var(--primary-font), sans-serif;
    letter-spacing: 0.1px;
  }

  &__item {
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
    overflow: hidden;
    display: block;

    &:hover {
      text-decoration: none;
    }
  }

  &__item-content {
    padding: 16px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    background-color: #fff;
  }

  &__item-content-left {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    h6 {
      font-size: 18px;
      color: var(--primary-color);
      font-weight: 700;
      font-family: var(--primary-font), sans-serif;
      text-transform: capitalize;
      max-width: 460px;
      margin-bottom: 0;

      &.complete {
        color: #0f2b41;
      }
    }

    p {
      color: #505a62;
      opacity: 0.5;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      font-family: var(--primary-font), sans-serif;
      letter-spacing: 0.1px;
      margin-bottom: 0;
    }
  }

  &__item-content-badge {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #0f2b41;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #0f2b41;
    font-weight: 700;
    font-family: var(--primary-font), sans-serif;
  }

  &__item-progress-bar {
    padding: 0 15px;
  }

  &__border {
    border: 2px solid #ced0d2;
    border-radius: 8px;
  }

  &__item-button {
    height: 30px;
    background-color: var(--primary-color);
    border-radius: 17px;
    padding: 0 15px;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 15px;
    justify-self: flex-end;
  }

  &__item-tag {
    width: 110px;
    background-color: $black-blue;
    border-radius: 8px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 15px;
    justify-self: flex-end;
  }
}
