.find-groups {
  h5 {
    font-size: 22px!important;
  }

  .find-groups-modal-body {
    margin-top: -7px;
  }

  .close {
    font-size: 2.2em;
    font-family: sans-serif;
    font-weight: 300;
    padding-top: 10px;
  }
}
