@import '../../assets/styles/colors.scss';

// lets hide it visually

.engage-checkbox-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  height: 24px;
  width: 24px;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  overflow: hidden;
}

.engage-checkbox-container.checked {
  background-color: var(--primary-color);
}

.engage-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

form input[type='radio'],
form input[type='checkbox'] {
  height: 24px;
  width: 24px;
}

.engage-checkmark {
  position: absolute;
  top: -1px;
  left: -1px;
}
