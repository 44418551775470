@import 'colors';

.feed {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  overflow-y: hidden;
  justify-content: center;

  &.scroll {
    overflow-y: auto;
  }

  a {
    text-decoration: none;
  }

  .feed-content-holder {
  }

  .feed-content-central-container {
    align-items: center;
    justify-content: center;
  }

  .feed-content {
    width: 100%;
    margin: 30px auto 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;

    &__lower-margin {
      margin-top: 25px;
    }
  }

  .feed-block-title {
    font-size: 22px;
    color: $black;
    font-weight: 600;
    line-height: 38px;
    margin: 24px 0 8px 0;
  }

  .feed-pinnned-icon {
    height: 25px;
  }

  .feed-item-group-privacy-container {
    display: flex;
    flex-direction: row;
  }

  .feed-item-group-privacy-InnerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-top: 5px;
  }

  .feed-item-group-privacy {
    height: 22px;
    color: #2d2d2d;
    font-family: var(--primary-font);
    font-size: 13px;
    letter-spacing: -0.09px;
    line-height: 22px;
    margin: 0%;
    padding-left: 6px;
  }

  .feed-item-group-privacy-line {
    margin: 5px 16px 10px 16px;
    background-color: #e4e6e7;
    height: 1px;
  }

  .feed-block-empty {
    font-size: 18px;
    color: $fg-color-main;
    opacity: 0.5;
    width: 100%;
    text-align: center;
    max-width: 55%;
    margin: 5% auto 0;
  }

  .feed-item {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 8px 0 rgba(78, 90, 99, 0.15);
    overflow: hidden;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }

  .feed-item-new-group {
    overflow: hidden;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
  }

  .feed-item--global {
    background-color: rgb(232, 241, 247);
  }

  .feed-item-tag-container {
    padding: 0 0 10px 16px;
  }

  .feed-item-tag {
    width: min-content;
    padding: 4px 10px;
    border-radius: 4px;
    background-color: $black-blue;
    color: $white;
    font-weight: 600;
    font-size: 14px;
  }

  .feed-item-header {
    padding: 16px 16px 14px;
  }

  .feed-item-header-image {
    flex-shrink: 0;
    margin-right: 8px;
    border-radius: 8px;
  }

  .feed-item-header-text {
    margin: 0;
    font-weight: 600;
    color: $black;
    line-height: 24px;
  }

  .feed-item-header-link {
    font-weight: 600;
    color: $black;
    text-decoration: none;
  }

  .feed-item-header-normal-text {
    font-weight: initial;
  }

  .feed-item-header-collapse {
    color: #505a62;
    text-decoration: none;
  }

  .feed-item-header-time {
    color: $fg-color-main;
    font-size: 13px;
    line-height: 22px;
  }

  .feed-item-content {
    color: $fg-color-main;
    line-height: 24px;
    padding: 8px 16px;
  }

  .feed-item-content-text {
    white-space: pre-wrap;
  }

  .feed-item-content-link {
    text-decoration: none;
    color: var(--primary-color);
    height: 24px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    max-width: 100%;
  }

  .feed-item-content-image-loader {
    width: 100%;
    background-color: #f3f4f5;
    border-radius: 8px;
    height: 200px;
    margin: 16px 0 6px;
  }

  .feed-item-content-image-holder {
    position: relative;
    width: 100%;
    height: 350px;
    border-radius: 8px;
    overflow: hidden;
    margin: 16px 0 6px;
  }
  .feed-item-content-image {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .feed-item-content-url-holder {
    background-color: #f4f4f4;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .feed-item-content-url-image {
    height: 238px;
    width: 100%;
    object-fit: cover;
  }

  .feed-item-content-url-title {
    color: var(--primary-color);
    font-weight: bold;
    letter-spacing: -0.13px;
    margin: 8px 16px 0;
  }

  .feed-item-content-url-link {
    color: #505a62;
    font-size: 14px;
    margin: 2px 16px 13px;
  }

  .feed-item-footer {
    color: $fg-color-main;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding: 10px 16px 15px;
    align-self: flex-start;
  }
  .feed-item-footer-container {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    background-color: #ffffff;
  }

  .feed-item-footer-button-container {
    display: flex;
    border-top: 0.5px solid #d8d8d8;
  }

  .feed-item-footer-control-button {
    width: 100%;
    background-color: #ffffff;
    color: #505a62;
    font-size: 15px;
    font-weight: bold;
    line-height: 26px;
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-of-type {
      border-right: 1px solid #d8d8d8;
    }

    &.liked {
      color: #dc3545;
    }

    &.disabled-button {
      color: #7e868d;
      cursor: initial;
    }

    .icon {
      margin-right: 7px;
      font-size: 18px;
    }
  }

  .feed-item-footer-comments-block {
    padding: 0 16px 16px;
    background-color: #f0f0f0;
    border-top: 1px solid #cacdcf;
    display: flex;
    flex-direction: column;
  }

  .feed-item-footer-comment-holder {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
  }

  .feed-item-footer-comments-avatar {
    flex-shrink: 0;
    margin-right: 8px;

    &.with-margin {
      margin-top: 15px;
    }
  }

  .feed-item-footer-comments-button {
    height: 34px;
    border-radius: 17px;
    align-self: flex-end;
    background-color: var(--primary-color);
    cursor: pointer;
    padding: 0 20px;
    min-width: 90px;
    color: #ffffff;
    font-weight: bold;
    line-height: 34px;
    text-align: center;
    margin-top: -13px;
    border: none;
  }

  .feed-item-footer-comments-total {
    color: $fg-color-main;
    font-size: 14px;
    line-height: 24px;
    align-self: flex-end;
    margin-bottom: 8px;
    margin-top: 15px;
  }

  .feed-item-footer-comment {
    background-color: #fff;
    border-radius: 0 18px 18px 18px;
    padding: 12px 10px 10px 16px;
  }

  .feed-item-footer-comment-owner {
    margin: 0 0 5px;
    color: var(--primary-color);
    font-size: 16px;
    line-height: 14px;
    font-weight: 600;
    a {
      color: var(--primary-color);
      font-weight: 600;
    }
  }

  .feed-item-footer-comment-text {
    margin: 0;
    color: $fg-color-main;
    font-size: 14px;

    a {
      color: var(--primary-color);
      font-weight: 700;
    }
  }

  .feed-item-footer-comment-date {
    color: $fg-color-main;
    font-size: 13px;
    align-self: flex-end;
    flex-shrink: 0;
  }

  .feed-item-footer-comments-more {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: bold;
    align-self: center;
    margin-top: 5px;
  }

  .feed-item-footer-comment-like-holder {
    margin-left: 66px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: $fg-color-main;
  }

  .feed-item-footer-comment-like-button {
    color: var(--primary-color);
    cursor: pointer;

    &.liked {
      color: #af365f;
    }
  }

  .feed-item-content-poll {
    padding-top: 10px;
  }

  .feed-item-content-poll-total {
    color: $fg-color-main;
    font-size: 15px;
    margin: 6px 0 0;
  }

  .feed-poll-item-option {
    cursor: pointer;
    width: calc(50% - 4px);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    min-height: 48px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    margin-bottom: 8px;
    transition: border 0.2s, color 0.2s;

    &:hover,
    &:hover .feed-poll-item-option-circle {
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    &:nth-child(2n) {
      margin-left: 4px;
    }
    &:nth-child(2n + 1) {
      margin-right: 4px;
    }
  }

  .feed-poll-item-option-finished {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .feed-poll-item-option-progress {
    height: 28px;
    width: 100%;
    border-radius: 14px;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
  }

  .feed-poll-item-option-progress-bg {
    background: linear-gradient(90deg, var(--primary-color) 0%, var(--primary-color) 100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 14px;
    opacity: 0.5;
  }

  .feed-poll-item-option-progress-text {
    color: #0f2b41;
    margin-left: 16px;
    position: relative;
    z-index: 1;

    &.voted {
      font-weight: 600;
    }
  }

  .feed-poll-item-option-progress-value {
    font-weight: bold;
    color: #0f2b41;
    width: 50px;
    text-align: center;
    flex-shrink: 0;
  }

  .feed-poll-item-option-circle {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border 0.2s;
  }

  .feed-poll-item-option-check {
    color: var(--primary-color);
    font-size: 13px;
  }

  .feed-item-event-block {
    margin-bottom: 17px;
    border: 1px solid #d1d5da;
    border-radius: 8px;
    overflow: hidden;
  }

  .feed-item-event-image-holder {
    position: relative;
    width: 37%;
    flex-shrink: 0;
    overflow: hidden;
    background-color: #f3f4f5;
  }

  .feed-item-event-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .feed-item-event-date-holder {
    width: 16%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 18px;
  }

  .feed-item-event-date {
    border: 3px solid rgba(175, 54, 95, 0.5);
    border-radius: 6px;
    width: 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
  }

  .feed-item-event-date-month {
    color: #af365f;
    font-size: 17px;
    font-weight: bold;
    line-height: 28px;
  }

  .feed-item-event-date-day {
    color: $black;
    font-size: 27px;
    line-height: 28px;
  }

  .feed-item-event-info-holder {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 17px 17px 17px 0;
  }

  .feed-item-event-info-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: var(--primary-color);
    word-break: break-word;
  }

  .feed-item-event-info-time {
    font-size: 15px;
    font-weight: 600;
    color: $fg-color-main;
    margin-top: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .feed-item-event-info-place {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    color: var(--primary-color);
  }

  .feed-item-event-info-button {
    cursor: pointer;
    height: 34px;
    border-radius: 17px;
    background-color: var(--primary-color);
    color: #ffffff;
    font-weight: bold;
    line-height: 34px;
    align-self: flex-end;
    padding: 0 20px;
    margin-top: 18px;
    flex-shrink: 0;
  }

  .feed-item-highfive-header {
    height: 200px;
    background-color: #e2eff5;
    position: relative;
    display: flex;
    overflow: hidden;
  }

  .feed-item-highfive-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
  }

  .feed-item-highfive-hands-image {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 66px;

    &.create-item {
      top: 45px;
    }
  }

  .feed-item-highfive-header-text {
    margin: 16px 0 0 16px;
    font-weight: 600;
    color: $black;
    position: relative;
    z-index: 1;

    a {
      color: $black;
    }
  }

  .feed-item-highfive-header-text-small {
    color: $fg-color-main;
    font-size: 13px;
    margin-left: 5px;
    font-weight: normal;
  }

  .feed-item-highfive-header-image {
    border-radius: 8px;
    position: absolute;
    top: 55px;

    &.left-image {
      transform: rotate(-5deg);
      left: calc(50% - 143px);
    }

    &.right-image {
      transform: rotate(5deg);
      right: calc(50% - 143px);
    }
  }

  .feed-item-highfive-user-info {
    display: flex;
    justify-content: center;
    color: $fg-color-main;
    padding-top: 16px;
  }

  .feed-item-highfive-user {
    margin: 0 5px;
    color: var(--primary-color);
    font-weight: bold;
  }

  .feed-item-highfive-title {
    color: #0f2b41;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    line-height: 44px;
    margin: 0;
  }

  .feed-item-highfive-text {
    color: $fg-color-main;
    line-height: 24px;
    text-align: center;
    margin: 0 0 20px;
  }

  .feed-group-header {
    padding-top: 25px;
  }

  .feed-group-header-skeleton {
    opacity: 0.1;
  }

  .feed-group-header-content {
    width: 100%;
    margin: 0 auto;
  }

  .feed-group-header-back-link {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: 24px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .feed-group-header-back-link-icon {
    margin-right: 8px;
    font-weight: normal;
    height: 13px;
  }

  .feed-group-header-image {
    flex-shrink: 0;
    margin-right: 15px;
    align-self: flex-start;

    &__default {
      height: 100px;
      width: 100px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #dadcdd;
    }
  }

  .feed-group-header-buttons-container {
    display: flex;
    flex-direction: column;
  }

  .feed-group-header-button {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    align-self: flex-end;
    margin-left: 15px;
    margin-bottom: 10px;

    &.disabled-b {
      opacity: 0.5;
      cursor: default;
    }
  }

  .feed-group-header-title {
    font-size: 32px;
    color: #0f2b41;
    font-weight: bold;
    letter-spacing: -0.65px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .feed-group-header-desc {
    color: #505a62;
    font-size: 15px;
    font-weight: 300;
  }

  .feed-group-header-category {
    color: #505a62;
    font-size: 15px;
    font-weight: 600;
  }

  .feed-group-header-amount {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 600;
  }

  .feed-content-back-button {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .feed-item-footer-splitter {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: $fg-color-main;
    margin: 0 5px;
    flex-shrink: 0;

    &.white {
      background-color: #fff;
    }

    &.grey {
      background-color: #505a62;
    }
  }

  .feed-group-header-modal-body {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  .feed-group-header-modal-button {
    width: calc(50% - 8px);
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    height: 44px;
    color: var(--primary-color);
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s, color 0.2s;

    &:hover {
      background-color: var(--primary-color);
      color: #fff;
    }
  }

  .feed-item-content-image-fullscreen {
    max-height: 100%;
    max-width: 80%;
    margin-bottom: 36px;
    display: flex;

    .feed-item-fullscreen-image-close {
      position: absolute;
      top: 120px;
      right: 36px;
      cursor: pointer;
    }

    .feed-item-content-image {
      object-fit: contain;
    }
  }

  .feed-group-management-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .feed-group-management-side-line {
      border-top: 1px solid $fg-color-main;
      flex: 1;
      min-width: 30px;
    }

    .feed-group-management-text {
      margin: 0 10px;
      color: $fg-color-main;
    }
  }
  .feed-employee-onboarding {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .feed-employee-onboarding-header {
    color: $black;
    font-weight: 600;
    font-size: 14px;
  }
  .feed-employee-onboarding-content {
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
    padding: 20px 0px;
  }
  .feed-employee-onboarding-title {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 18px;
  }
  .feed-employee-onboarding-task-info {
    color: $black-blue;
    font-size: 12px;
  }
  .feed-employee-onboarding-img-bg {
    background-color: rgb(224, 236, 242);
  }

  .feed-employee-onboarding-go-button {
    height: 34px;
    background-color: var(--primary-color);
    border-radius: 17px;
    padding: 0 30px;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  .feed-employee-onboarding-img {
    width: 80px;
    height: 80px;
  }
}

.manage {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  align-items: flex-start;

  a {
    text-decoration: none;
  }

  .manage-divider {
    height: 1px;
    background-color: rgba(15, 43, 65, 0.25);
  }
  .manage-group-header {
    background-color: #ffffff;
    padding-top: 25px;
  }
  .manage-group-footer-splitter {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #0e2a41;
    margin: 0 5px;
    flex-shrink: 0;

    &.white {
      background-color: #0e2a41;
    }
  }
  .manage-group-header-title {
    font-size: 32px;
    color: #0e2a41;
    font-weight: bold;
    letter-spacing: -0.65px;
    line-height: 32px;
    margin-bottom: 5px;
  }
  .manage-group-header-content {
    width: 60%;
    margin: 0 auto;
  }
  .manage-group-header-back-link {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: 24px;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .manage-group-content {
    width: 100%;
    margin: 52px auto 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;

    &__lower-margin {
      margin-top: 25px;
    }
  }
}
.feed-group-header-modal-body {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.feed-group-header-modal-button {
  width: calc(50% - 8px);
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  height: 44px;
  color: var(--primary-color);
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
}
