.challenge-badge-count {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #0f2b41;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
  color: #0f2b41 !important;
  font-weight: 700 !important;
  font-family: var(--primary-font), sans-serif;
}
