.ant-skeleton.ant-skeleton-with-avatar {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 1px 8px 0 rgba(78, 90, 99, 0.15);
}

.ant-select-dropdown-menu-item {
  padding: 0;
}

.ant-select-selection {
  width: 100%;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent;
}

.ant-checkbox-group {
  width: 100%!important;
}

.ant-radio-group {
  width: 100%!important;
}

.ant-radio-button-wrapper {
  margin-right: 10px;
  border-radius: 50% !important;
  height: 25px;
  padding: 0!important;
  width: 25px;
  outline: none!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-radio-button-wrapper:after {
  background: white;
}

.ant-radio-button-wrapper:focus {
  outline: none!important;
}

.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
  font-size: 16px;
}

.ant-radio-button-wrapper-checked {
  .radio-inner {
    display: block;
    background: var(--primary-color);
  }
}


.ant-checkbox-wrapper {
  flex: 0;
  position: absolute!important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: var(--primary-color);
}

.ant-radio-button-wrapper-checked:focus {
  outline: none!important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
  -webkit-box-shadow: -1px 0 0 0 var(--primary-color);
  box-shadow: -1px 0 0 0 var(--primary-color);
}
.ant-checkbox-input {
  height: 24px;
  width: 24px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  background-color: #FFFFFF;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary-color);
}
.ant-checkbox-inner {
  height: 24px;
  width: 24px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  background-color: #FFFFFF;
  visibility:not;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
}

.ant-checkbox-inner:after {
  left: 28%;
  width: 7.714286px;
  height: 12.142857px;}

.ant-checkbox-checked:after {
  display: none;
}

.ant-checkbox-inner:focus {
  outline: none;
}

.ant-checkbox-checked::after {
  height: 0!important;
}
.ant-select-selection__placeholder {
  color: #808399;
}

.ant-select-dropdown-menu-item:hover {
  //background-color: pink!important;
}


.__react_component_tooltip.place-top {
  margin-top: -10px;
  background-color: rgba(15, 43, 65, 0.9);
}

