.single-line-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.single-line-no-overflow {
  white-space: nowrap;
}

.truncate-3-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.truncate-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.truncate-1-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.remove-highlight-link {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.font-weight-semibold {
  font-weight: 600;
}
