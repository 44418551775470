.max-vw-4 {
  max-width: 4vw;
}

.max-vw-3 {
  max-width: 3vw;
}

.max-vw-2 {
  max-width: 2vw;
}

.max-vh-60 {
  max-height: 60vh;
}

.max-vh-10 {
  max-height: 10vh;
}
