@import 'colors';

.switch {
  position: relative;
  display: inline-block;
  width: 340px;
  height: 46px;
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.text {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
  font-family: var(--primary-font), sans-serif;
  position: absolute;

  &.off {
    left: 40px;
    top: 12px;
    color: white;
  }

  &.on {
    right: 56px;
    top: 12px;
  }

  &.active {
    color: white;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid var(--primary-color);
}

.slider:before {
  position: absolute;
  content: '';
  height: 34px;
  width: 150px;
  left: 6px;
  bottom: 5px;
  background-color: #505a62;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(178px);
  -ms-transform: translateX(178px);
  transform: translateX(178px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 23px;
}

.slider.round:before {
  border-radius: 23px;
}

.black-color {
  color: black !important;
}

.white-background {
  .slider:before {
    background: white !important;
  }
}
