@import "colors";

.tab-holder {
  display: flex;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  flex-shrink: 0;

  .tab-item {
    width: 100%;
    height: 50px;
    background-color: var(--primary-color);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    border-right: 1px solid rgba(4,43,67,0.25);

    &.selected {
      background-color: #fff;
      color: #4E5A63;
      font-weight: initial;
      border-color: white;
      margin-left: -1px;
    }

    &:last-of-type {
      border-right: none;
    }
  }

  .tab-item-icon {
    margin-right: 6px;
    font-size: 26px;
    width: 32px;
  }
}

.manage-holder {
  display: flex;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  flex-shrink: 0;

  .manage-item {
    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: #fff;
    color: #4E5A63;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    border: #4E5A63;

    &.selected {
      background-color: #fff;
      color: var(--primary-color);
      border-bottom: 5px solid var(--primary-color);
      font-weight: 600;
      margin-left: -1px;
    }

    &:last-of-type {
      border-right: none;
    }
  }
}
