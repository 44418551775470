.group-search-item {
  max-width: 20%;
  min-height: 130px;
  padding: 0 3px;
  margin-bottom: 6px;

  & > div {
    border-radius: 8px;
    overflow: hidden;
  }

  &__text {
    font-weight: 600;
    font-size: 15px;
  }
}
