.engage {
  &__top-header {
    background-color: #042b43;
    width: 100%;
    height: 155px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__top-header-content {
    width: 100%;
    max-width: 580px;

    h2 {
      color: #fff;
      margin-bottom: 15px;
      font-family: var(--primary-font), sans-serif;
      font-weight: 700;
      letter-spacing: -0.75px;
      font-size: 38px;
    }
  }

  &__top-header-tabs {
    width: 100%;
    display: flex;
    height: 50px;
    border-radius: 8px 8px 0 0;
    background-color: var(--primary-color);
    overflow: hidden;
  }

  &__top-header-tab {
    width: calc(1 / 3 * 100%);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    p {
      font-size: 16px;
      color: #fff;
      font-weight: 600;
      font-family: var(--primary-font), sans-serif;
      margin-bottom: 0;
    }

    &.active {
      background-color: #fff;

      p {
        color: #4e5a63;
        font-weight: 500;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
