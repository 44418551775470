
$white: #FFFFFF;
$black: #0F2B41;
$black-blue: #042B43;

$blue: #0274A7;
$red: #BE265F;
$fg-color-main: #505A62;
$grey: #242424;

$header-link-selected: #0075AC;
