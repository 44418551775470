@import 'colors';

.update-challenge {
  max-width: 350px;

  h5 {
    font-size: 20px;
    font-family: var(--primary-font), sans-serif;
  }

  p {
    color: #505a62;
    font-size: 16px;
  }

  &__button {
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 8px;
    font-size: 17px;
    font-weight: bold;
    color: #fff;
    font-family: var(--primary-font), sans-serif;

    &:hover {
      color: #fff;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .close {
    font-weight: 300;
    font-size: 2.2em;
    padding-top: 10px;
    font-family: sans-serif;
  }

  .ant-slider {
    .ant-slider-rail {
      background-color: #ced4d8;
      height: 8px;
      border-radius: 4px;
      width: calc(100% + 8px);
    }

    .ant-slider-handle {
      border: none;
      background-color: var(--primary-color);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      height: 26px;
      width: 26px;
      margin-top: -11px;

      &:focus {
        box-shadow: none;
      }
    }

    .ant-slider-track {
      background-color: transparent;
    }

    .ant-slider-dot {
      position: absolute;
      top: 16px;
      width: 2px;
      height: 8px;
      margin-left: 4px;
      background-color: #ced4d8;
      cursor: pointer;
      border: none;
    }

    .ant-slider-mark-text {
      top: 15px;
      color: #0f2b41;
      font-size: 13px;
      margin-left: 5px;
      font-family: var(--primary-font), sans-serif;
    }
  }
}
