@import '../colors';

%holder {
  margin-top: 80px;
  padding: 25px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #ffffff;
}

.progress-bar-container {
  z-index: 1000;
  width: 100%;
}
.onboarding-agreements-checkbox {
  font-weight: 600;
}
.agreements-checkbox-container {
  padding-top: 20px;
  padding-bottom: 40px;
  width: 580px;
  padding-left: 30px;
}
.onboarding-next-button {
  height: 48px;
  width: 200px;
}
.on-boarding {
  .col-xl-4 {
    flex: 0 0 600px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .offset-xl-4 {
    margin-left: auto;
  }
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .upload-img-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97px;
    height: 97px;
    max-width: 135px;
    margin: auto;
    margin-top: 19px;
    cursor: pointer;
    position: relative;
    border-radius: 8px;

    .add-img {
      position: absolute;
      bottom: -17px;
      right: -8px;
    }
  }
  .picture-text {
    font-size: 16px;
    letter-spacing: -0.19px;
    line-height: 24px;
    text-align: center;
    color: $fg-color-main;
    margin: 20px 0 0;
  }
  .back-to-form {
    font-size: 15px;
    padding: 10px;
    margin-top: 20px;
    font-weight: 600;
    color: var(--primary-color);
    font-family: var(--primary-font), sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  .back-to-form-icon {
    margin-right: 8px;
    height: 12px;
  }

  .form-holder {
    @extend %holder;
    .title {
      font-size: 28px;
      font-weight: 600;
      letter-spacing: -0.45px;
      line-height: 44px;
      color: $black-blue;
      font-family: var(--primary-font), sans-serif;
    }
    .form-text {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.15px;
      color: $fg-color-main;
      font-family: var(--primary-font), sans-serif;
      margin-bottom: 5px;
      margin-top: 10px;
    }
  }
  .steps {
    &__holder {
      @extend %holder;
      //padding: 0 0 31px;
      margin-top: 0;
    }
    &__img {
      height: 200px;
      object-fit: cover;
      width: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &__header {
      font-size: 28px;
      font-weight: 600;
      font-family: var(--primary-font), sans-serif;
      letter-spacing: -0.45px;
      color: $black-blue;
      padding: 0 15px;
      margin-bottom: 8px;
      margin-top: 18px;
    }
    &__text {
      font-size: 16px;
      font-family: var(--primary-font), sans-serif;
      letter-spacing: -0.19px;
      line-height: 24px;
      color: $fg-color-main;
      padding: 0 15px;
      white-space: pre-wrap;
    }
  }
  .button-holder {
    background-color: #f0f0f0;
    padding: 16px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
  }
  .create-profile__panel-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .create-profile__screen-cont {
    display: flex;
    flex: 1;
    min-height: 100%;
  }
  .create-profile__left-panel {
    display: flex;
    flex: 1;
    flex-direction: column;
    // justify-content: flex-start;
    // padding: 0 60px;
    height: calc(100vh - 100px);
  }
  .create-profile__right-panel {
    display: flex;
    flex: 1;
    max-height: 1100px;
    width: 100%;
    height: 100%;
  }
  .create-profile__button-cont {
    display: inline-flex;
    height: 60px;
  }

  .field-container.active .field-label {
    color: $black;
  }

  .user-profile-form {
    .field-container {
      margin-bottom: 3px;
    }
  }

  .onboarding-privacy-bottom-container {
    padding: 20px 0;
  }
}
