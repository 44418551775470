//@import "colors";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  transition: all 0.3s ease;
  width: 100%;
  min-height: 33px;
  font-size: 1.1rem;
  background-color: transparent;
  font-weight: 600;
  font-family: var(--primary-font), sans-serif;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &.small {
    max-height: 33px;
    font-size: 0.8125rem;
  }

  &.medium {
    min-height: 46px;
    font-size: 17px;
  }

  &.large {
    min-height: 52px;
    font-size: 1.313rem;
  }

  // svg {
  //   margin-right: .5rem;
  //   flex: 0 0 auto;

  //   .fill {
  //     transition: fill .3s ease;
  //   }
  // }

  &.primary {
    background-color: var(--primary-color);
    color: #ffffff;

    // &:hover {
    //   background-color: darken(#0274a7, 10%);
    // }

    // svg .fill {
    //   fill: #FFFFFF;
    // }
  }

  &.primary-outline {
    color: black;
    border: 2px solid black;
    background-color: transparent;

    &:hover {
      color: darken(black, 10%);
      border-color: darken(black, 10%);

      svg .fill {
        fill: darken(black, 10%);
      }
    }

    svg .fill {
      fill: black;
    }
  }
  &.primary-violet {
    background-color: black;
    &:hover {
      background-color: darken(black, 10%);
    }
  }

  &.secondary {
    background-color: #ffffff;
    color: initial;

    &:hover {
      background-color: darken(#ffffff, 10%);
    }

    // svg .fill {
    //   fill: #E9E9E9;
    // }
  }
  &.secondary-outline {
    background-color: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);

    // &:hover {
    //   color: #ffffff;
    //   background-color: darken(#0274a7, 1%);
    // }
  }
  &.violet-outline {
    background-color: transparent;
    border: 1px solid black;
    color: black;

    &:hover {
      color: darken(black, 10%);
      border-color: darken(black, 10%);

      svg .fill {
        fill: darken(black, 10%);
      }
    }

    svg .fill {
      fill: black;
    }
  }
  &.white {
    background-color: white;
    color: violet;
    border-radius: 4px;

    &:hover {
      background-color: darken(white, 10%);
    }
  }
  &.white-outline {
    border: 2px solid white;
    background-color: transparent;
    border-radius: 4px;
    color: white;

    &:hover {
      color: darken(white, 10%);
      border-color: darken(white, 10%);
    }
  }

  &.disabled {
    background-color: darken(white, 40%);

    &:hover {
      background-color: darken(white, 40%);
      cursor: auto;
    }
  }
}
.modal-button {
  flex: 1 1 0%;
  background-color: var(--primary-color);
  max-width: 150px;
}
