@import '../../assets/styles/colors.scss';

.post-container {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .knowledgebase-item-content-image-holder {
    position: relative;
    width: 100%;
    height: 350px;
    border-radius: 8px;
    overflow: hidden;
  }
  .knowledgebase-item-content-image {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .post-header-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
  }
  .post-headerbar-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 18px 16px 0px 16px;
    align-items: center;
    justify-content: center;
  }
  .post-headerbar-container-with-image {
    position: absolute;
  }
  .post-headerbar-typelabel {
    border-radius: 6px;
    background-color: rgb(15, 32, 50);
    padding: 5px 8px;
    font-size: 15px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
  }
  .post-headerbar-categorylabel {
    display: flex;
    color: var(--primary-color);
    font-weight: 600;
  }
  .categorylabel-with-image {
    color: white;
  }
  .knowledgebase-header-text {
    height: 31px;
    width: 86px;
    color: #242424;
    font-family: var(--primary-font);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 33px;
    text-align: justify;
  }

  .post-detail-title {
    padding-bottom: 5px;
  }
  .post-detail-container {
    font-weight: 600;
    color: var(--primary-color);
    font-size: 18px;
  }
  .detail-label {
    color: var(--primary-color);
    // width: 100%;
    word-wrap: break-word;
  }
  .post-title {
    padding-bottom: 5;
    font-weight: bold;
    font-size: 18px;
  }
  .post-information-content {
    padding-top: 5px;
    word-wrap: break-word;
  }
}

.post-container-knowledgebase {
  border: 3px solid rgba(0, 0, 0, 0.05);
  margin-top: 15px;
  box-shadow: none;
}
