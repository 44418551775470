@import 'colors';

.daily-pulse {
  position: absolute;
  bottom: 200px;
  z-index: 999;
  width: calc((100% - 280px) * 0.6);
  max-width: 580px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #ffffff !important;
  overflow: visible !important;
  padding-bottom: 40px;
  border-radius: 10px;

  h5,
  p {
    color: #0f2b41;
    align-self: flex-start;
    font-family: var(--primary-font), sans-serif;
    margin-left: 24px;
  }

  h5 {
    font-size: 16px;
    font-weight: 700;
  }

  p {
    font-size: 12px;
    font-weight: 400;
  }

  &.red {
    .daily-pulse__animated-face,
    .ant-slider .ant-slider-handle {
      background-color: #af365f;

      transition: background-color 0.5s ease-in;
    }
  }

  &.yellow {
    .daily-pulse__animated-face,
    .ant-slider .ant-slider-handle {
      background-color: #fbb811;

      transition: background-color 0.5s ease-in;
    }
  }

  &.green {
    .daily-pulse__animated-face,
    .ant-slider .ant-slider-handle {
      background-color: #4ab793;

      transition: background-color 0.5s ease-in;
    }
  }

  &__animated-face {
    width: 100px;
    background-color: #4ab793;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.2), 0 4px 9px 0 rgba(140, 140, 140, 0.3);
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    padding: 15px;
  }

  &__done-button {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 160px;
    height: 50px;
    bottom: 15px;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: var(--primary-font), sans-serif;
    letter-spacing: 0.7px;
    line-height: 24px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff;
    cursor: pointer;
    background-color: var(--primary-color);
  }

  &__not-now {
    width: 80px;
    height: 34px;
    position: absolute;
    top: 16px;
    right: 18px;
    color: #0f2b41;
    font-size: 16px !important;
    font-family: var(--primary-font), sans-serif;
    font-weight: 600 !important;
    line-height: 24px;
    letter-spacing: 0.15px;
    cursor: pointer;
  }

  .ant-slider {
    width: 90%;
    margin-left: -4px;

    .ant-slider-rail {
      background-color: #091927;
      height: 8px;
      border-radius: 4px;
      width: calc(100% + 8px);
    }

    .ant-slider-handle {
      border: none;
      background-color: #00bb91;
      height: 26px;
      width: 26px;
      margin-top: -11px;

      &:focus {
        box-shadow: none;
      }
    }

    .ant-slider-track {
      background-color: transparent;
    }

    .ant-slider-dot {
      position: absolute;
      top: 16px;
      width: 2px;
      height: 8px;
      margin-left: 4px;
      background-color: #091927;
      cursor: pointer;
      border: none;
    }

    .ant-slider-mark {
      left: 10px;
      width: auto;
      right: 5px;
    }

    .ant-slider-mark-text {
      top: 15px;
      color: #0f2b41;
      font-size: 12px;
      margin-left: 4px;
      font-family: var(--primary-font), sans-serif;
    }
  }
}
