@import 'node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700&display=swap');

@import url('https://fonts.googleapis.com/css?family=Irish Grover:400,600,700&display=swap');
@import 'colors';
@import 'antd-override';
@import 'fields';
@import 'buttons';
@import 'signIn';
@import 'layout';
@import 'modal';
@import 'magic_link_preloader';
@import 'typograpy.scss';
@import 'components/createGroup';
@import 'components/dailyPulse';
@import 'components/challenges';
@import 'components/challengeBadgeCount';
@import 'components/dailyPulse.scss';
@import 'components/engage';
@import 'components/findGroups';
@import 'components/groupSearchResults';
@import 'components/inputWithMentions';
@import 'components/on-boarding';
@import 'components/portal';
@import 'components/singleChallenge';
@import 'components/switch';
@import 'components/updateChallenge';
@import 'components/thumbnailImage';
@import 'global';
@import 'components/header';
@import 'progressBar';
@import 'feed';
@import 'sidebar';
@import 'notificationsSidebar';
@import 'components/sidebarGroupBlock';
@import 'components/sidebarMoreBlock';
@import 'components/tabs';
@import 'components/createFeedItem';
@import 'components/activity';
@import 'components/notifications';
@import 'components/groupItem';
@import 'components/knowledgebase';

html,
body,
#root,
#root > .wrapper {
  height: 100%;
  // --primary-font: Poppins;
}

html {
  overflow: hidden;
}

body {
  font-family: var(--primary-font), sans-serif;
  background-color: #f9f9f9;
}

@font-face {
  font-family: 'InterstateMono';
  src: local('InterstateMono'), url('../fonts/InterstateMono.ttf') format('truetype');
}
