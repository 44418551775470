@import '../colors.scss';

.header {
  padding: 0 9px;
  background-color: #ffffff;
  flex-shrink: 0;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  min-height: 84px;
  max-width: 1440px;
  z-index: 1000;
  width: 100%;
  align-self: center;

  .explore-menu-item {
    padding-bottom: 10px;
  }

  .explore-menu-title {
    padding: 0;
    margin: 0;
    color: #505a62;
    font-family: var(--primary-font), sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--primary-color);
      text-decoration-thickness: 2px;
    }
  }

  .explore-menu-subtitle {
    padding: 0;
    margin: 0;
    color: #505a62;
    font-family: var(--primary-font), sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 300;
  }

  .explore-menu {
    z-index: 1;
    position: absolute;
    flex-direction: column;
    margin-top: 180px;
    width: 235px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .tittle {
    font-size: 16px;
    font-weight: 600;
    font-family: var(--primary-font), sans-serif;
    color: #4e5a63;
    margin: 0 25px;
  }

  .title-learning {
    margin: 0 0 0 32px;
  }

  .header-links-holder {
    height: 100%;
    display: flex;
    margin-left: 25px;
  }

  .fourth-link {
    font-size: 16px;
    color: $fg-color-main;
    text-decoration: none;
  }
  .header-link {
    font-size: 16px;
    color: $fg-color-main;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    &.selected {
      // color: var(--primary-color);
      font-weight: 600;
    }

    &.selected:after {
      content: '';
      width: 100%;
      height: 3px;
      border-radius: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      // background-color: var(--primary-color);
    }
  }

  .header-link-icon {
    margin-right: 8px;
  }

  .header-link-badge {
    position: absolute;
    left: 14px;
    top: -5px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    font-size: 10px;
    color: #ffffff;
    font-weight: 600;
    background-color: #af365f;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1px;
  }

  .header-edit {
    &--title {
      color: #505a62;
      font-size: 16px;
      letter-spacing: -0.19px;
      line-height: 24px;
      margin-bottom: 0;
    }

    &--subtitle {
      margin: 0;
      opacity: 0.5;
      color: #4e5a63;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.2px;
      line-height: 18px;
    }

    &--img {
      height: 45px;
      width: 45px;
      border-radius: 50%;
    }
  }

  .active {
    color: var(--primary-color);
    opacity: 1;
  }

  .header-right-section {
    flex: 1;
  }
}
