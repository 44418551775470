@import 'colors';

.input-with-mentions {
  width: 100%;
  font-family: var(--primary-font), sans-serif;
  .ant-form {
    width: 100%;
    margin-top: 15px;
    position: relative;

    .ant-form-item {
      margin-bottom: 5px;

      .ant-mention-wrapper {
        .ant-mention-editor {
          border-radius: 8px;
          border: none;
          box-shadow: none !important;
        }

        .public-DraftEditorPlaceholder-root {
          left: 11px;
          transform: translateY(50%);
          transition: all 0.2s;
          height: 22px;
          border-radius: 11px;

          &.public-DraftEditorPlaceholder-hasFocus {
            transform: translateY(-50%);
            background: #fff;

            .public-DraftEditorPlaceholder-inner {
              color: #0f2b41;
              font-size: 13px;
              padding: 0 5px;
            }
          }

          .public-DraftEditorPlaceholder-inner {
            padding: 0;
            font-size: 16px;
            font-family: var(--primary-font), sans-serif;
          }
        }

        .DraftEditor-editorContainer {
          max-height: 300px;
          overflow-y: auto;
        }

        .DraftEditor-editorContainer .public-DraftEditor-content {
          min-height: 100px;
          line-height: 26px;
          border-radius: 8px;
          border: 1px solid #505a62;
          padding: 10px 11px;
          display: flex;

          &:focus {
            border-color: var(--primary-color);
            outline: none !important;
          }

          span {
            span[data-offset-key] {
              span[data-text] {
                color: var(--primary-color);
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .ant-btn {
      background-color: var(--primary-color);
      border-radius: 17px;
      width: 89px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: var(--primary-font), sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      border: none;
      margin-left: auto;
    }
  }

  &.active .DraftEditor-editorContainer .public-DraftEditor-content {
    border-color: var(--primary-color) !important;
  }
  &.has-error .DraftEditor-editorContainer .public-DraftEditor-content {
    border-color: #be265f !important;
  }

  .mention-error {
    margin-top: -10px;
    margin-bottom: 8px;
  }

  .input-label {
    font-size: 16px;
    letter-spacing: 0.2px;
    background-color: #fff;
    margin-left: 10px;
    line-height: 16px;
    z-index: 1;
    padding: 0 4px;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, top 0.2s, font-size 0.2s;
    color: rgba(80, 90, 98, 0.5);
    position: absolute;
    top: 13px;
    border-radius: 8px;

    &.active {
      transform: none;
      color: $fg-color-main;
      font-size: 13px;
      top: -6.5px;
    }
  }
}
