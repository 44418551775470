@import 'colors';

.activity-container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  &--upper {
    margin: 0 auto;
    padding-top: 20px;

    .title {
      height: 31px;
      width: 86px;
      color: #242424;
      font-family: var(--primary-font);
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 33px;
      text-align: justify;
    }
    .header {
      color: $black;
      font-weight: 600;
      font-size: 14px;
    }

    .subtitle {
      width: 340px;
      margin: 0 auto;
      color: #0f2b41;
      font-size: 18px;
      letter-spacing: -0.21px;
      line-height: 26px;
      text-align: center;
      padding-bottom: 20px;
    }

    .blue-highlight {
      color: var(--primary-color);
    }

    .activity-count-container {
      // padding: 16px 18px;
      border-radius: 8px;
      background: #ffffff;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);

      .activity-count-container__upper {
        padding: 16px 18px;
      }

      .points {
        color: #0f2b41;
        font-size: 20px;
        letter-spacing: -0.19px;
        line-height: 24px;
      }

      .top-percentile-section {
        background-color: #f0f0f0;
        border-radius: 0 0 8px 8px;
        padding: 16px 9px;
        border-top: 1px solid #f0f0f0;
      }

      .badges {
        color: #0f2b41;
        font-size: 20px;
        letter-spacing: -0.19px;
        line-height: 24px;
      }

      .rank {
        color: #0f2b41;
        font-size: 16px;
        letter-spacing: -0.15px;
        line-height: 24px;
        margin: 0;
      }
    }
  }
}

.chart-progress-container {
  position: relative;
  display: flex;

  .circle {
    margin: 10px auto 10px;
    height: 240px;

    .ant-progress-inner {
      width: 240px !important;
      height: 240px !important;
      margin: auto;
      position: relative;

      svg {
        overflow: inherit;
        transform: rotate(-175deg);
      }
    }
  }

  .inner-points {
    font-size: 60px;
    color: #0f2b41;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 17px;
      font-weight: 100;
      margin: 0;
      color: #808080;
    }
  }
}

.ant-progress-circle-trail {
  stroke: #e0e0e0 !important;
}

.badges-container {
  width: 582px;
  margin: auto;

  &--upper {
    margin-top: 24px;
    .title {
      color: #0f2b41;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: -0.32px;
      line-height: 38px;
      margin: 0;
    }

    .pop-up-desc {
      display: flex;
      background-color: white;
      padding: 15px 10px;
      color: #808080;
      min-width: 335px;
      justify-content: flex-start;
      font-weight: 500;
      border-radius: 10px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    }

    .active-badges {
      display: flex;
      .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4px;
        height: 49px;
        width: 49px;
        border: 3px solid #fbb811;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 10px;
      }

      .badge:hover {
        border: 3px solid #0f2b41;
      }
    }

    .inactive-badges {
      display: flex;
      .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4px;
        height: 49px;
        width: 49px;
        border: 3px solid rgba(80, 90, 98, 0.5);
        border-radius: 50%;
        cursor: pointer;
        margin-right: 10px;
      }

      .badge-description {
        margin-bottom: 0;
        width: 220px;
      }
    }
  }
}
