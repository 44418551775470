@import '../../assets/styles/colors.scss';

.employee-onboarding {
  color: #505a62;
  font-size: 16px;
  letter-spacing: -0.19px;
  line-height: 24px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .onboarding-container {
    height: 100%;
    width: 100%;

    .oboarding-title {
      height: 31px;
      width: 86px;
      color: #242424;
      font-family: var(--primary-font);
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 33px;
      text-align: justify;
      margin: 20px;
      margin-top: 30px;
    }

    .skip-link {
      font-size: 15px;
      font-weight: 600;
      color: var(--primary-color);
      margin-left: 9px;
      cursor: pointer;
    }

    .task-item-container {
      display: flex;
      flex: 1;

      .line-container {
        display: flex;
        flex-direction: column;
        width: 40px;
        align-items: center;

        .line {
          width: 1px;
          flex: 1;
        }

        .filled-line {
          border: 1px solid var(--primary-color);
        }

        .outer-circle {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          width: 22px;
          height: 22px;
          border: 1px solid var(--primary-color);
        }
        .inner-circle {
          width: 18px;
          height: 18px;
          border-radius: 9px;
          border: 1px solid var(--primary-color);
          display: flex;
        }

        .circle-filled {
          background-color: var(--primary-color);
        }
      }
    }

    .task-item-content {
      display: flex;
      background-color: white;
      padding: 16px 24px 16px 16px;
      flex: 1;
      border-radius: 8px;
      box-shadow: 0 1px 8px 0 rgba(78, 90, 99, 0.15);
      position: relative;
      width: 100%;

      .entity-title {
        font-weight: 600;
      }
      .entity-desc {
        font-weight: normal;
      }

      .start-button {
        color: var(--primary-color);
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-top: 8px;
      }
    }
  }
}
