.portal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(4, 43, 67, .8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  overflow: auto;
}
